<template>
    <v-content>
        <v-breadcrumbs :items="items" large></v-breadcrumbs>
        <v-container fluid>
            <!-- <v-row no-gutters>
        <v-col cols="10"></v-col>
        <v-col cols="2">
            <v-btn cols="2" class="success" @click.stop="onDownload">
                <v-icon>get_app</v-icon>
                Export
            </v-btn>
        </v-col>
    </v-row>-->
            <v-expansion-panels multiple hover>
                <v-expansion-panel>
                    <v-expansion-panel-header>{{$t('filter')}}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-container fluid>
                            <v-row col="12">
                                <v-row align="start" justify="start" class="mt-4">
                                    <v-col class="d-flex" cols="12" md="4">
                                    <v-select :items="customYear"
                                              :label="$t('from_date')"
                                              v-model="serverParams.FromDate"
                                              prepend-inner-icon="event"
                                              dense
                                              outlined></v-select>
                                </v-col>
                                    <v-col class="d-flex" cols="12" md="4">
                                    <v-select :items="customYear"
                                              :label="$t('to_date')"
                                              v-model="serverParams.ToDate"
                                              prepend-inner-icon="event"
                                              dense
                                              outlined></v-select>
                                </v-col>

                                    <!--<v-col cols="12" md="6">
                                        <v-menu v-model="snackbar.menu1"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                :close-on-content-click="false"
                                                min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field v-model="serverParams.FromDate"
                                                              :label="$t('from_date')"
                                                              clearable
                                                              prepend-icon="event"
                                                              readonly
                                                              v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="serverParams.FromDate">

                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>



                                    <v-col cols="12" md="6">
                                        <v-menu v-model="snackbar.menu2"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                :close-on-content-click="false"
                                                min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field v-model="serverParams.ToDate"
                                                              :label="$t('to_date')"
                                                              clearable
                                                              prepend-icon="event"
                                                              readonly
                                                              v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="serverParams.ToDate">

                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>-->

                                    <v-col cols="12" md="4">
                                        <v-autocomplete v-model="serverParams.Country"
                                                        :items="ProvinceList"
                                                        :label="$t('port_of_entry_list')"
                                                        clearable
                                                        dense
                                                        outlined
                                                        @change="getBranchList"
                                                        item-text="value"
                                                        item-value="value"></v-autocomplete>
                                    </v-col>
                                    <!--<v-col cols="12" md="6">
                                <v-autocomplete v-model="serverParams.BranchID"
                                                :items="BranchList"
                                                :label="$t('form.branch_name')"
                                                clearable
                                                item-text="value"
                                                item-value="id"></v-autocomplete>
                            </v-col>-->
                                    <!--<v-col class="d-flex" cols="12" sm="3">
                                <v-menu v-model="snackbar.menu1"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        :close-on-content-click="true"
                                        min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field v-model="serverParams.FromDate"
                                                      label="From"
                                                      clearable
                                                      prepend-icon="event"
                                                      readonly
                                                      v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="serverParams.FromDate">

                                    </v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col class="d-flex" cols="12" sm="3">
                                <v-menu v-model="snackbar.menu2"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field v-model="serverParams.ToDate"
                                                      label="To"
                                                      clearable
                                                      prepend-icon="event"
                                                      readonly
                                                      v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="serverParams.ToDate">

                                    </v-date-picker>
                                </v-menu>
                            </v-col>-->

                                    <v-col class="d-flex" cols="12" sm="12">
                                        <v-spacer></v-spacer>
                                        <v-btn class="mx-2" dark small color="primary" @click="loadItems">
                                            <v-icon dark>search</v-icon>
                                            {{$t('search')}}
                                        </v-btn>
                                        <v-btn color="rgb(157, 25, 25)" small dark @click="clearInput">
                                            <i class="material-icons">clear</i>{{$t('clear')}}
                                        </v-btn>
                                        <v-btn @click.stop="onDownload" small class="success float-right mx-2">
                                            <v-icon>get_app</v-icon>
                                            {{$t('download_to_excel')}}
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                    </v-col>



                                </v-row>
                            </v-row>
                        </v-container>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <vue-good-table mode="remote"
                            @on-page-change="onPageChange"
                            @on-sort-change="onSortChange"
                            @on-column-filter="onColumnFilter"
                            @on-per-page-change="onPerPageChange"
                            :pagination-options="{ enabled: true,mode: 'records' }"
                            :rows="rows"
                            max-height="500px"
                            :fixed-header="true"
                            style="z-index:1"
                            :totalRows="totalRecords"
                            :columns="columns"
                            :isLoading.sync="snackbar.isLoading"
                            styleClass="vgt-table condensed">
                <template slot="table-column" slot-scope="props">
                    <span>
                        {{ $t(props.column.label) }}
                    </span>
                </template>
                <!--<template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'datedOn'">
                <span>{{props.row.datedOn | moment("DD-MMM-YYYY")}}</span>
            </span>

        </template>-->
                 <template slot="loadingContent">
                    <v-progress-circular :size="50"
                                         color="primary"
                                         indeterminate></v-progress-circular>

                </template>
            </vue-good-table>
            <v-snackbar v-model="snackbar.snackbar"
                        color="success"
                        :timeout="snackbar.timeout"
                        top>
                eo
                {{ callbackResponse.message }}
                <v-btn dark
                       text
                       @click="snackbar.snackbar = false">
                    Close
                </v-btn>
            </v-snackbar>
        </v-container>
        <v-row class="mt-7">
            <div class="col-12" style="color: gray; text-align: right">
                {{$t('source')}} : {{$t('department_of_immigration')}}
            </div>
        </v-row>
    </v-content>
</template>

<script>
    import axios from "axios";

    export default {
        name: "ArrivalTourist-ArrivalReport",
        props: [
            'moduleName'
        ],
        components: {},
        computed: {
            customYear() {
                let year = []
                for (let i = 0; i < 99; i++) {
                    year.push(`20${i < 10 ? '0' : ''}${i}`)
                }
                return year
            },
            items() {
                return [
                    {
                        text: this.$t('report'),
                        exact: true,
                        to: '/reportModules'
                    },
                    {
                        text: this.$t('tourist_arrival_report_menu'),
                        exact: true,
                        to: '/reportModules/reportTourist-Arrival'
                    },
                    {
                        text: this.$t('arrival_report'),
                        disabled: true,
                        exact: true
                    }
                ]
            }
        },
        methods: {
            clearInput() {
                this.serverParams.ToDate = null
                this.serverParams.FromDate = null
                this.serverParams.Country = null
                this.loadItems();
            },
            getNow: function () {
                const today = new Date();
                const date = today.getFullYear();
                this.serverParams.FromDate = date;
                this.serverParams.ToDate = date;
            },
            formResponse(data) {
                if (data.message) {
                    this.snackbar.formModal = false
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.callbackResponse = data;
                    this.snackbar.snackbar = true
                    this.loadItems();
                } else {
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.snackbar.formModal = false
                }
            },
            async onDownload() {
                this.snackbar.isLoading = true;
                let param = {

                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                    ToDate: this.serverParams.ToDate + '-01-01',
                    FromDate: this.serverParams.FromDate + '-01-01',
                    ProvinceID: this.serverParams.ProvinceID,
                    BranchID: this.serverParams.BranchID,
                    Country: this.serverParams.Country
                };
                await axios({
                    url: 'Immigration/DownloadUpdatedCustomerInformationListAsync',
                    method: 'POST',
                    responseType: 'blob',
                    data: param
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'UpdatedCustomerInformationListAsync.xlsx'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                    this.snackbar.isLoading = false;
                });
                //await axios({
                //    url: 'Report/DownloadSample',
                //    method: 'POST',
                //    responseType: 'blob'
                //}).then((response) => {
                //    const url = window.URL.createObjectURL(new Blob([response.data]));
                //    const link = document.createElement('a');
                //    link.href = url;
                //    link.setAttribute('download', 'PilgrimageFile.xlsx'); //or any other extension
                //    document.body.appendChild(link);
                //    link.click();
                //});
            },
            onResponse(data) {
                if (data == true) {
                    this.dialogDelete = false
                    this.loadItems();
                } else {
                    this.dialogDelete = false
                }
            },
            async loadItems() {
                this.snackbar.isLoading = true;

                let param = {
                    PageSize: this.serverParams.perPage == -1 ? this.totalRecords : this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                    ToDate: this.serverParams.ToDate + '-12-31',
                    FromDate: this.serverParams.FromDate + '-01-01',
                    ProvinceID: this.serverParams.ProvinceID,
                    BranchID: this.serverParams.BranchID,
                    Country: this.serverParams.Country
                };

                axios.post('Immigration/GetUpdatedCustomerInformationListAsync', param)
                    .then(response => {
                        this.rows = response.data.data;
                        this.totalRecords = response.data.totalCount;
                        console.log('datajdfhjdhgjdfhgjhfdjgh', response);
                        this.snackbar.isLoading = false;
                    })
                    .catch(err => {
                        console.log(err)
                        this.snackbar.isLoading = false;
                    });

            },


            //async getProvinceList() {
            //    const ProvinceList = await axios.get('Province/DDLProvinceList')
            //    this.ProvinceList = ProvinceList.data
            //    console.log('province list: ', this.ProvinceList)
            //},

            async getProvinceList() {
                const ProvinceList = await axios.get('Immigration/DDLPortofEntryList')
                this.ProvinceList = ProvinceList.data
                console.log('Port of Entry list: ', this.ProvinceList)
            },


            async getBranchList(id) {
                const BranchList = await axios.get('Branch/DDLBranchListForImmigrationByProvinceID?ProvinceID=' + id)
                this.BranchList = BranchList.data
            },


            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({ page: params.currentPage });
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({ perPage: params.currentPerPage });
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },

            addModal() {
                this.snackbar.formModal = true
                this.snackbar.createFormComponent = true
            },

            editItem(item) {
                this.snackbar.formModal = true
                this.snackbar.editFormComponent = true
                this.historyData.editItemIndex = item.personInformationID
            },

            deleteItem(props) {
                this.dialogDelete = true;
                this.deleteFormData.url = "Mountain/DeleteHistoryInformationByIDAsync/" + props.personInformationID;
            },
        },
        mounted() {
            this.getNow()
            this.loadItems()
            this.getProvinceList()


        },
        data() {
            return {
                tab: 'tab-monthlyReport',
                callbackResponse: {
                    timeout: 6000
                },
                columns: [


                    {
                        label: "s_no",
                        width: "70px",
                        field: "countIndex"
                    },
                    {
                        label: 'full_name',
                        width:"280px",
                        field: 'fullName'
                    },
                    {
                        label: 'gender',
                        width:"150px",
                        field: 'gender',
                    },
                    {
                        label: 'nationality',
                        width:"150px",
                        field: 'nationality',
                    },
                    //{
                    //    label: 'actions',
                    //    width:"70px",
                    //    field: "actions"
                    //}
                    //,
                    //{
                    //    label: 'FullName',
                    //    field: 'fullName'
                    //},
                    //{
                    //    label: 'PassportNumber',
                    //    field: 'passportNumber'
                    //},
                    //{
                    //    label: 'DateOFBirth	',
                    //    field: 'dateOFBirth	'
                    //},
                    //{
                    //    label: 'Country',
                    //    field: 'country'
                    //},
                    //{
                    //    label: 'Nationality',
                    //    field: 'nationality'
                    //},
                    //{
                    //    label: 'IDValidity',
                    //    field: 'iDValidity'
                    //},
                    //{
                    //    label: 'VisaValidity',
                    //    field: 'visaValidity'
                    //},
                    //{
                    //    label: 'ArrivalDeparture',
                    //    field: 'arrivalDeparture'
                    //},
                    {
                        label: 'port_of_entryexit',
                        width:"200px",
                        field: 'portOfEntryExit'
                    }
                    //{
                    //    label: 'PurposeOFVisit',
                    //    field: 'purposeOFVisit'
                    //},
                    //{
                    //    label: 'AirLineName',
                    //    field: 'AirLineName'
                    //},
                ],
                rows: [],
                ReportCats: [],
                dialogDelete: false,
                isLoading: false,
                totalRecords: 0,
                deleteFormData: [],
                formdata: [],
                historyData: {
                    totalRows: 0,
                    editItemIndex: null
                },
                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "countIndex",
                            type: ""
                        }
                    ],
                    page: 1,
                    perPage: 10
                },

                //paginationOptions: {
                //    enabled: true,
                //    mode: 'records',
                //    perPageDropdown: [20, 50, 100, 200],
                //    dropdownAllowAll: false,
                //    nextLabel: 'next',
                //    prevLabel: 'prev',
                //    rowsPerPageLabel: 'Rows per page',
                //    ofLabel: 'of',
                //    pageLabel: 'page', // for 'pages' mode
                //    allLabel: 'All',
                //},
                snackbar: {
                    color: '',
                    snackbar: false,
                    text: '',
                    timeout: 6000,
                    isLoading: false,
                    formModal: false,
                    createFormComponent: false,
                    editFormComponent: false,
                    menu1: false,
                    menu2: false,
                    /* breadcrumbsItem: [
                         {
                             text: 'Dashboard',
                             disabled: false,
                             to: 'MountaineeringDashboard',
                             exact: true

                         },
                         {
                             text: 'Mountaineering History Information',
                             disabled: true,
                         },
                     ],*/
                    dynamicComponent: {
                        current: 'HistoryForm'
                    }
                }
            };
        },
    }
</script>

<style scoped lang="scss">
    $blue: #265b93;

    .myCustomTable {
        text-align: center;
        width: fit-content;
        min-width: 100%;

        thead, tbody {
            width: 100%;
        }

        thead {
            width: 100%;
            background-color: $blue;
            color: white;
        }

        td, th {
            padding: 1rem
        }

        border: 1px solid #eee;

        tr, td, th {
            border: 1px solid #eee;
        }
    }

    .table-container {
        width: 100%;
    }

    .headerBackground {
        background-color: $blue;
        color: white;
    }
</style>